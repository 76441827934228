if (typeof kDefaultBoxMsg === 'undefined') {
    var kDefaultBoxMsg = "&gt;&nbsp;Click <i>run program</i> to compile and run your code. Click <i>grade code</i> to submit and grade your code.";
}

if (typeof kConErrMsg === 'undefined') {
    var kConErrMsg = 'Sorry, we couldn\'t connect to the server. Check your internet connection or check our <a class="text-dark" href="http://status.dev.cs128.tk" target="_blank">status page</a>.';
}

if (typeof kDefaultBoxCss === 'undefined') {
    var kDefaultBoxCss = {
        "min-height": "6.1rem",
        "max-height": "18.5rem",
        "background-color": "black",
        color: "white",
        "font-family": "'IBM Plex Mono', monospace"
    };
}

function enableButtons() {
    $('.activity-grade').removeAttr('disabled');
    $('.activity-run').removeAttr('disabled');
    $('.activity-lb').removeAttr('disabled');
}

function disableButtons() {
    $('.activity-grade').attr('disabled', true);
    $('.activity-run').attr('disabled', true);
    $('.activity-lb').attr('disabled', true);
}

function getAttendance(sId) {
    $.ajax({
        url: `https://attendance.cs128.org/api/student/attendance/${sId}`,
        type: 'GET',
        headers: { 'X-Sess-Id': $('meta[name="sess-id"]').attr("content") },
        success: function (res) {
            var attendance_dat = JSON.parse(res);
            if(attendance_dat.attended) {
                let attendance_success_div = $(`#x-attendance-record-${sId}-success`);
                attendance_success_div.find('.attendance-record').html(`<b>${attendance_dat.actor}</b><br>${attendance_dat.timestamp}`);
                attendance_success_div.show();
            } else {
                $(`#x-attendance-record-${sId}-fail`).show();
            }
            
        },
        fail: function (res) { }
    });
}

window.cs128SyntaxHighlighterInit = function cs128SyntaxHighlighterInit() {
    $('pre code').each(function(i, block) {
        hljs.highlightBlock(block);
    });
    $('code.hljs').each(function(i, block) {
        hljs.lineNumbersBlock(block);
    });
}

function escapeHtmlCharacters(text) {
    return text.replace(/&/g, "&amp;").replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(/"/g, "&quot;").replace(/(?:\r\n|\r|\n)/g, '<br>')
}

function compileRequestOnReadyState(currentId, btn, readyState, statusCode, response) {
    if (readyState !== 4) return;

    enableButtons(); toggleDivBlur(currentId);
    if (readyState === 4 && statusCode === 0) {
        sendAlert(kConErrMsg);
        btnToError(btn);
        return;
    }

    if (statusCode >= 300) {
        populateErrMsg(response);
        btnToError(btn);
        return;
    }

    var json = JSON.parse(response);
    var currentDiv = $("#activity-" + currentId + "-output");

    if (json["timeout"]) {
        currentDiv.html("Your code <strong style=\"color: yellow\">TIMED OUT</strong> (10s) and was terminated.");
        btnToSuccess(btn);
        return;
    }

    if (json["fail"]) {
        currentDiv.html("<p style=\"margin-bottom: 0.5em;\">Your code <strong style=\"color: red\">FAILED</strong> to execute.</p>" + escapeHtmlCharacters(json["error"]));
        btnToSuccess(btn);
        return;
    }

    if(json["output"].length === 0) {
        currentDiv.html("Successful execution with no output.");
        btnToSuccess(btn);
        return;
    }

    currentDiv.html(escapeHtmlCharacters(json["output"]));
    btnToSuccess(btn);
}

function CompileCode(id, req) {
    const btn = stylizeButton($("#activity-" + id + "-run"), "btn-info", "btn-warning", '<i class="fas fa-ellipsis-h"></i>');
    toggleDivBlur(id);
    disableButtons();

    var runRequest = new XMLHttpRequest;
    runRequest.open("POST", "https://core-apis.cs128.org/compile", true);
    runRequest.withCredentials = true;
    runRequest.setRequestHeader("Content-Type", "application/json");
    runRequest.setRequestHeader("X-Sess-Id", $('meta[name="sess-id"]').attr('content'));
    runRequest.onreadystatechange = function() {
        compileRequestOnReadyState(id, btn, runRequest.readyState, runRequest.status, runRequest.response);
    }
    runRequest.send(JSON.stringify(req));
}

function GradeCode(id, req) {
    const btn = stylizeButton($("#activity-" + id + "-grade"), "btn-dark", "btn-warning", '<i class="fas fa-ellipsis-h"></i>');
    toggleDivBlur(id);
    disableButtons();

    var runRequest = new XMLHttpRequest;
    runRequest.open("POST", "https://core-apis.cs128.org/grade", true);
    runRequest.withCredentials = true;
    runRequest.setRequestHeader("Content-Type", "application/json");
    runRequest.setRequestHeader("X-Sess-Id", $('meta[name="sess-id"]').attr('content'));
    runRequest.onreadystatechange = function() {
        gradeRequestOnReadyState(id, btn, runRequest.readyState, runRequest.status, runRequest.response);
    }
    runRequest.send(JSON.stringify(req));
}

function gradeRequestOnReadyState(currentId, btn, readyState, statusCode, response) {
    if (readyState !== 4) return;

    enableButtons(); toggleDivBlur(currentId);

    if (readyState === 4 && statusCode === 0) {
        sendAlert(kConErrMsg);
        btnToError(btn);
        return;
    }

    if (statusCode >= 300) {
        populateErrMsg(response);
        btnToError(btn);
        return;
    }

    var json = JSON.parse(response);
    var currentDiv = $("#activity-" + currentId + "-output");

    if (json["timeout"] === "true") {
        currentDiv.html("Your code <strong style=\"color: yellow\">TIMED OUT</strong> (10s) and was terminated. Submission not graded (0%).");
        btnToSuccess(btn);
        return;
    }

    if (json["fail"] === "true") {
        currentDiv.html("<p style=\"margin-bottom: 0.5em;\">Your code <strong style=\"color: red\">FAILED</strong> to execute. Submission not graded (0%).</p>" + escapeHtmlCharacters(json["error"]));
        btnToSuccess(btn);
        return;
    }
    currentDiv.html("Graded! Fetching results...");
    printTestOutput(json, currentDiv, btn);
}

function SaveCode(id, req, notify) {
    const btn = stylizeButton($("#activity-" + id + "-save"), "btn-light", "btn-warning", '<i class="fas fa-ellipsis-h"></i>');
    disableButtons();

    var runRequest = new XMLHttpRequest;
    runRequest.open("POST", "https://core-apis.cs128.org/save", true);
    runRequest.withCredentials = true;
    runRequest.setRequestHeader("Content-Type", "application/json");
    runRequest.setRequestHeader("X-Sess-Id", $('meta[name="sess-id"]').attr('content'));
    runRequest.onreadystatechange = function() {
        saveRequestOnReadyState(id, btn, runRequest.readyState, runRequest.status, runRequest.response, notify);
    }
    runRequest.send(JSON.stringify(req));
}

function saveRequestOnReadyState(currentId, btn, readyState, statusCode, response, notify) {
    enableButtons();
    if (readyState !== 4) return;

    if (readyState === 4 && statusCode === 0) {
        sendAlert(kConErrMsg);
        btnToError(btn);
        return;
    }

    if (statusCode >= 300) {
        populateErrMsg(response);
        btnToError(btn);
    } else if (statusCode === 200) {
        btnToSuccess(btn);
        if(notify) sendSuccess("Saved!")
    } else {
        // unreachable
    }
}

function GetMaxScore(aid, agmax) {
    var runRequest = new XMLHttpRequest;
    runRequest.open("GET", "https://core-apis.cs128.org/submission_max/" + aid, true);
    runRequest.withCredentials = true;
    runRequest.setRequestHeader("X-Sess-Id", $('meta[name="sess-id"]').attr('content'));
    runRequest.onreadystatechange = function() {
        getMaxScoreOnReadyState(aid, agmax, runRequest.readyState, runRequest.status, runRequest.response)
    }
    runRequest.send();
}

function getMaxScoreOnReadyState(aid, agmax, readyState, statusCode, response) {
    if (readyState !== 4) return;

    function populateDiv(cls, msg) {
        $('#res_display_activity_' + aid).removeClass().addClass("alert alert-" + cls);
        $('#res_display_activity_text_' + aid).html(msg);
    }

    if (readyState === 4 && statusCode === 0) {
        populateDiv("danger", "Couldn't connect to server while fetching your score information.")
        return;
    }

    if (statusCode >= 300) {
        response ? populateDiv("danger", response)
            : populateDiv("danger", "Something went wrong while fetching your score information.");
        return;
    }

    var json = JSON.parse(response);
    if (json["none_graded"]) {
        populateDiv("warning",
            "You have not attempted this activity. You currently have <strong>0%</strong> for this activity.");
    } else {
        const timestamp = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long', timeZone: 'US/Central' }).format(new Date(json["timestamp"]))
        const max_sc = json["max"] < 0 ? 0 : json["max"];
        if(json["max"] >= agmax) {
            populateDiv("success",
                "You received <strong>full credit (" + max_sc + " of " + agmax + " – " + (max_sc*100/agmax).toFixed(2) + "%)</strong> for this activity from your attempt " +
                "on <strong>" + timestamp + "</strong>");
        } else {
            populateDiv("info",
                "You received <strong>partial credit (" + max_sc + " of " + agmax + " – " + (max_sc*100/agmax).toFixed(2) + "%)</strong> for this activity from your attempt " +
                "on <strong>" + timestamp + "</strong>");
        }
    }
}

function invokeSubmissionComprehensive(submissionId, aid, editors, invokeFn) {
    $('#activity-'+aid+'-submission-review-modal-info').html("Loading...")
    $('#activity-'+aid+'-submission-review-modal-feedback').html("").hide()
    $('#activity-'+aid+'-submission-review-modal-diff').html("")
    $('#activity-'+aid+'-submission-review-modal').modal('show');
    bootstrap.Popover.getInstance($('#activity-'+aid+'-history')).hide();
    GetSubmissionComprehensive(submissionId, aid, editors, invokeFn);
}

function GetSubmissionComprehensive(submissionId, aid, editors, invokeFn) {
    var runRequest = new XMLHttpRequest;
    runRequest.open("GET", "https://core-apis.cs128.org/submission_comprehensive/" + submissionId, true);
    runRequest.withCredentials = true;
    runRequest.setRequestHeader("X-Sess-Id", $('meta[name="sess-id"]').attr('content'));
    runRequest.onreadystatechange = function() {
        getSubmissionComprehensiveOnReadyState(aid, submissionId, editors, invokeFn, runRequest.readyState, runRequest.status, runRequest.response)
    }
    runRequest.send();
}

function getSubmissionComprehensiveOnReadyState(aid, submissionId, editors, invokeFn, readyState, statusCode, response) {
    if (readyState !== 4) return;

    const divSummary = $('#activity-'+aid+'-submission-review-modal-info')

    if (readyState === 4 && statusCode === 0) {
        divSummary.html("Something went wrong while connecting to server to fetch this submission.")
        return;
    }

    if (statusCode >= 300) {
        response ? divSummary.html(response)
            : divSummary.html("Something went wrong while connecting to server to fetch this submission.")
        return;
    }

    var json = JSON.parse(response);
    populateSubmissionComprehensive(aid, submissionId, editors, invokeFn, json)
}

function populateSubmissionComprehensive(aid, submissionId, editors, invokeFn, submission) {
    const divSummary = $('#activity-'+aid+'-submission-review-modal-info')
    const divFeedback = $('#activity-'+aid+'-submission-review-modal-feedback')
    const divDiff = $('#activity-'+aid+'-submission-review-modal-diff')

    const curDate = new Date(submission["created_at"]);
    const timestamp = new Intl.DateTimeFormat('en-US', { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false, timeZone: 'US/Central' }).format(curDate)

    let summary = `<h5>Submission: <strong>${ timestamp }</strong></h5><strong>Status:</strong> `
    let diff = ""

    function escapeOutput(text) {
        return text.length === 0 ? "Successful execution with no output." : escapeHtmlCharacters(text);
    }

    if(submission["is_graded"]) {
        const json = JSON.parse(submission["response"]);
        if(json["fail"] === "true") {
            summary += "Autograder <strong class='text-danger'>FAILED</strong> because of errors<br><strong>Score:</strong> 0<br><strong class='d-block my-2'>Compiler Output</strong>"
            divFeedback.html(escapeOutput(json["error"])).show()
        } else if(json["timeout"] === "true") {
            summary += "Autograder <strong class='text-warning'>TIMED OUT</strong><br><strong>Score:</strong> 0"
        } else {
            summary += `<strong class='text-success'>Graded</strong><br><strong>Score:</strong> ${json["score"]}<br><strong class='d-block my-2'>Autograder Feedback</strong>`
            printTestOutput(json, divFeedback)
            divFeedback.show()
        }
    } else {
        if(submission["response"] === "200") {
            summary += "<strong class='text-info'>Saved</strong><br>"
        } else {
            const json = JSON.parse(submission["response"]);
            if(json["fail"] === "true") {
                summary += "Run request <strong class='text-danger'>FAILED</strong> because of errors<br><strong class='d-block my-2'>Compiler Output</strong>"
                divFeedback.html(escapeOutput(json["error"])).show()
            } else if(json["timeout"] === "true") {
                summary += "Run request <strong class='text-warning'>TIMED OUT</strong><br>"
            } else {
                summary += "<strong class='text-success'>Compiled and Executed</strong><br><strong class='d-block my-2'>Program Output</strong>"
                divFeedback.html(escapeOutput(json["output"])).show()
            }
        }
    }
    divSummary.html(summary);

    diff = '<button class="btn btn-dark btn-sm mb-2 float-end revert-submission-' + aid + '" data-id="' + submissionId + '"><i class="fas fa-cloud-download-alt me-2"></i>Revert to this version</button><strong class="d-block my-3">Codes</strong>'

    for(var i = 0; i < submission["codes"].length; i++) {
        const curIndex = submission["codes"][i]['local_file_id'];
        const curFileName = submission["codes"][i]['file_name'];
        const currentCode = editors[curIndex]['editor'].getSession().getValue();
        const fileDiffs = Diff.createTwoFilesPatch("(working version) "+curFileName, "(this submission) "+curFileName,
            currentCode, submission["codes"][i]['code']);
        diff += Diff2Html.getPrettyHtml(fileDiffs,
            {inputFormat: 'diff', showFiles: false, matching: 'lines', outputFormat: 'side-by-side'})
    }

    divDiff.html(diff);
    $('#activity-' + aid + '-submission-review-modal-diff > .revert-submission-' + aid).click(function() {
        invokeFn(3, $(this).data("id"), aid);
        $('#activity-'+aid+'-submission-review-modal').modal('hide');
    })
}

function populateErrMsg(response) {
    const text = response ? response : "Our bad! Something's wrong with our server. Please try again.";
    sendAlert(text);
}

function sendAlert(text) {
    alertify.error('<i class="fas fa-times" style="margin-right: 0.6rem"></i> ' + text);
}

function sendInfo(text) {
    alertify.warning('<i class="fas fa-info-circle" style="margin-right: 0.6rem"></i> ' + text);
}

function sendSuccess(text) {
    alertify.success('<i class="fas fa-check-circle" style="margin-right: 0.6rem"></i> ' + text);
}

function stylizeButton(btn, currentClass, newClass, newHtml) {
    const currentState = { "classRemoved": currentClass, "classAdded": newClass, "html": btn.html(), "btn": btn };
    btn.removeClass(currentClass).addClass(newClass).html(newHtml);
    return currentState;
}

function btnToError(btn) {
    stylizeButton(btn["btn"], btn["classAdded"], "btn-danger", '<i class="fas fa-exclamation-triangle fa-fw"></i>')
    setTimeout(function() {
        stylizeButton(btn["btn"], "btn-danger", btn["classRemoved"], btn["html"])}, 3000)
}

function btnToSuccess(btn) {
    stylizeButton(btn["btn"], btn["classAdded"], "btn-success", '<i class="fas fa-check fa-fw"></i>')
    setTimeout(function() {
        stylizeButton(btn["btn"], "btn-success", btn["classRemoved"], btn["html"])}, 3000)
}

function toggleDivBlur(id) {
    var currentDiv = $("#activity-" + id + "-output");
    currentDiv.toggleClass('blur-div');
}

function printTestOutput(json_data, currentDiv, btn) {
    var json = json_data["autograder_feedback"];
    var outhtml = "";
    var totalcorrect = 0;
    var totalcorrectpossible = 0;
    outhtml = outhtml.concat("<table><tr><th style=\"width: 10rem;\"></th><th></th></tr>");
    for(var i = 0; i < json.length; i++) {
        var obj = json[i];
        totalcorrectpossible++;

        outhtml = outhtml.concat("<tr>");

        if(obj["pass"] === "true") {
            totalcorrect++;
            outhtml = outhtml.concat("<th><strong style=\"color: #008000; font-weight: bold;\"><i class=\"fas fa-check fa-fw\"></i> PASSED (+");
            outhtml = outhtml.concat(obj["points"]);
            outhtml = outhtml.concat(")</strong></th><th style=\"font-weight: normal\">");
            outhtml = outhtml.concat(escapeHtmlCharacters(obj["name"]));
            outhtml = outhtml.concat("</th>");
        } else {
            outhtml = outhtml.concat("<th><strong style=\"color: #DC143C; font-weight: bold;\"><i class=\"fas fa-times fa-fw\"></i> FAILED (");
            if(parseInt(obj["points"]) >= 0) {
                outhtml = outhtml.concat("+");
            }
            outhtml = outhtml.concat(obj["points"]);
            outhtml = outhtml.concat(")</strong></th><th style=\"font-weight: normal\">");
            outhtml = outhtml.concat(escapeHtmlCharacters(obj["name"]));
            outhtml = outhtml.concat("</th></tr><tr><th></th><th style=\"font-weight: normal; font-size: 0.9rem;\">");
            outhtml = outhtml.concat(escapeHtmlCharacters(obj["feedback"]));
            outhtml = outhtml.concat("</th>");
        }

        outhtml = outhtml.concat("</tr>");
    }
    outhtml = outhtml.concat("</table>");

    if(totalcorrectpossible === totalcorrect) {
        currentDiv.html("<p class=\"autograde\">Test Summary: ALL " + totalcorrectpossible + " test(s) <strong style=\"color: #008000; font-weight: bold;\">PASSED</strong> (" + json_data["score"] + " pts). Great job!</p>" + outhtml);
    } else {
        currentDiv.html("<p class=\"autograde\">Test Summary: <strong>" + totalcorrect + "</strong> of " + totalcorrectpossible + " test(s) PASSED (<strong>" + json_data["score"] + "</strong>" + " pts)</p>" + outhtml);
    }

    if(btn) btnToSuccess(btn);
}

function invokeSubmissionRevert(submissionId, editors, invokeFn, originalCodes, aid) {
    if(submissionId === -1) {
        if(originalCodes) {
            alertify.confirm("Are you sure you want to revert back to the starter version?",
                function(){ populateCodes(editors, originalCodes, invokeFn, aid) },
                function(){}).set({title:"Confirm"}).set({labels:{ok:"Yes, I'm sure", cancel: "Abort"}});
        }
    } else {
        alertify.confirm("Are you sure you want to revert back to this version?",
            function(){ GetSubmissionCodes(submissionId, editors, invokeFn, aid) },
            function(){}).set({title:"Confirm"}).set({labels:{ok:"Yes, I'm sure", cancel: "Abort"}});
    }
}

function populateCodes(editors, codes, invokeFn, aid) {
    for(var i = 0; i < codes.length; i++) {
        const curIndex = codes[i]['local_file_id'];
        editors[curIndex]['editor'].setValue(codes[i]['code']);
        editors[curIndex]['editor'].clearSelection();
    }
    sendSuccess("We reverted your submission back. We will now attempt to save this.")
    $('#progress_display_activity_'+aid).hide();
    invokeFn(2, true);
}

function GetSubmissionCodes(submissionId, editors, invokeFn, aid) {
    var runRequest = new XMLHttpRequest;
    runRequest.open("GET", "https://core-apis.cs128.org/submission_files/" + submissionId, true);
    runRequest.withCredentials = true;
    runRequest.setRequestHeader("X-Sess-Id", $('meta[name="sess-id"]').attr('content'));
    runRequest.onreadystatechange = function() {
        getSubmissionCodesOnReadyState(editors, invokeFn, runRequest.readyState, runRequest.status, runRequest.response, aid)
    }
    runRequest.send();
    sendInfo("We are working on retrieving this submission. Hang tight!")
}

function getSubmissionCodesOnReadyState(editors, invokeFn, readyState, statusCode, response, aid) {
    if (readyState !== 4) return;

    if (readyState === 4 && statusCode === 0) {
        sendAlert("Something went wrong when retrieving this submission. Try again.")
        return;
    }

    if (statusCode >= 300) {
        response ? sendAlert(response)
            : sendAlert("Something went wrong when retrieving this submission. Try again.");
        return;
    }

    var json = JSON.parse(response);
    sendSuccess("Got it! Updating the editors...")
    populateCodes(editors, json, invokeFn, aid)
}

function GetSubmissions(aid, invokeFn, isInitial) {
    var runRequest = new XMLHttpRequest;
    var div = $('#activity-' + aid + '-history-popover-content')

    runRequest.open("GET", "https://core-apis.cs128.org/submissions/" + aid, true);
    runRequest.withCredentials = true;
    runRequest.setRequestHeader("X-Sess-Id", $('meta[name="sess-id"]').attr('content'));
    runRequest.onreadystatechange = function() {
        if(isInitial) getSubmissionsOnReadyState(aid, div, runRequest.readyState, runRequest.status, runRequest.response, invokeFn, true);
        else getSubmissionsOnReadyState(aid, div, runRequest.readyState, runRequest.status, runRequest.response, invokeFn);
    }
    runRequest.send();
    div.text('Loading...')
}

function getSubmissionsOnReadyState(aid, div, readyState, statusCode, response, invokeFn, isInitial) {
    if (readyState !== 4) return;

    if (readyState === 4 && statusCode === 0) {
        div.text("Something went wrong when retrieving this submission. Try again.")
        if(isInitial) bootstrap.Popover.getInstance($('#activity-'+aid+'-history')).hide();
        return;
    }

    if (statusCode >= 300) {
        response ? div.text(response)
            : div.text("Something went wrong when retrieving this submission. Try again.");
        if(isInitial) bootstrap.Popover.getInstance($('#activity-'+aid+'-history')).hide();
        return;
    }

    var json = JSON.parse(response);
    if(json.length === 0) div.html("No history. Click on the load icon (<i class=\"fas fa-sync-alt text-dark\"></i>) to reload.")
    else {
        if(isInitial) {
            populateSubmissions(aid, div, json, true);

            const curDate = new Date(json[0]["created_at"]);
            const timestamp = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long', timeZone: 'US/Central' }).format(curDate)
            $('#progress_display_activity_text_'+aid)
                .html('Welcome back! We found the most recent version you were working on, which was ' +
                    'saved on <strong>'+timestamp+'</strong>. Want to keep working on this?<div class="mt-3 text-center">' +
                    '<button class="btn btn-dark btn-sm revert-submission-' + aid + '" ' +
                    'data-id="' + json[0]["id"] + '"><i class="fas fa-cloud-download-alt me-2"></i>Load this version</button>' +
                    '<button class="btn btn-outline-dark btn-sm ms-2" data-bs-dismiss="alert" aria-label="Close">' +
                    '<i class="fas fa-times me-2"></i>Dismiss</button></div>');
            $('#progress_display_activity_'+aid).show();
        } else {
            populateSubmissions(aid, div, json);
        }
        $('.revert-submission-' + aid).click(function() {
            invokeFn(3, $(this).data("id"), aid);
        })
        $('.detail-submission-' + aid).click(function() {
            invokeFn(4, $(this).data("id"), aid);
        })
    }
    if(isInitial) bootstrap.Popover.getInstance($('#activity-'+aid+'-history')).hide();
}

function populateSubmissions(aid, div, submissions, isInitial) {
    var table = '<table class="table table-hover table-sm mb-0"><thead><tr><th scope="col">#</th><th scope="col">Type</th><th scope="col">Score</th><th scope="col">Timestamp (CT)</th><th scope="col"></th></tr></thead><tbody>';
    var max = {"i": 0, "d": -1}
    for(var i = 0; i < submissions.length; i++) {
        const curDate = new Date(submissions[i]["created_at"]);
        const timestamp = new Intl.DateTimeFormat('en-US', { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false, timeZone: 'US/Central' }).format(curDate)
        const score = submissions[i]["score"] < 0 ? 0 : submissions[i]["score"];
        if(submissions[i]["is_graded"] && (max["d"] < score)) {
            max["i"]=i;max["d"]=score;
        }
        table += `<tr id="s-history-${aid}-${i}"><th scope="row">${submissions.length-i}</th>` +
            `<td>${ submissions[i]["is_graded"] ? "Grade" : ( submissions[i]["response"] === "200" ? "Save" : "Run" )}</td>` +
            `<td>${ submissions[i]["is_graded"] ? submissions[i]["score"] : 'N/A' }</td>` +
            `<td>${ timestamp }</td>` +
            `<td><a class="revert-submission-${aid} btn btn-sm p-0 m-0 no-webkit text-dark" data-id="${submissions[i]["id"]}"><i class="fas fa-cloud-download-alt"></i></a>` +
            `<a class="detail-submission-${aid} btn btn-sm p-0 m-0 no-webkit text-dark" data-id="${submissions[i]["id"]}"><i class="fas fa-eye ms-1"></i></a></td></tr>`;
    }
    table += '<tr><th scope="row">0</th>' +
        '<td colspan="3">Starter Code</td>' +
        `<td><a class="revert-submission-${aid} btn btn-sm p-0 m-0 no-webkit" data-id="-1"><i class="fas fa-cloud-download-alt"></i></a></td></tr>`;

    table += '</tbody></table>';
    div.html(table);
    if(max["d"]!==-1) {
        if(isInitial) bootstrap.Popover.getInstance($('#activity-'+aid+'-history')).show();
        $('#s-history-'+aid+"-"+max["i"]).addClass("table-success");
    }
    if(isInitial) bootstrap.Popover.getInstance($('#activity-'+aid+'-history')).hide();
}

function onYouTubeIframeAPIReady() {
    ytEmbedProcessAll();
}
window.ytEmbedProcessAll = function ytEmbedProcessAll() {
$('.cs128-vidembed-yt').each(function() {
    if($(this).data("ready")) return;
    let embed_id = $(this).data("block");
    let embed_tok = $(this).data("token");
    let embed_nonce = $(this).data("nonce");
    let tc = true;
    let embed_player = null;
    try {
        embed_player = new YT.Player($(this).attr('id'), {
            events: {
                'onReady': () => revealPlayer(embed_id),
                'onStateChange': (e) => ytEmbedEvent(e, embed_tok, embed_nonce, true)
            },
            playerVars: {
                'origin': window.location.origin
            }
        });
    } catch (err) { tc = false; }
    if(tc) {
        setInterval(function(){
            let event_g = { "data": embed_player.getPlayerState(), "target": {"playerInfo": embed_player.playerInfo } };
            ytEmbedEvent(event_g, embed_tok, embed_nonce, false);
        }, 30000);
    }
})
}
function revealPlayer(id) {
    $(`#cs128-vidspinner-${id}`).hide();
    $(`#cs128-vidembed-${id}`).data("ready", true).show();
}
function ytEmbedEvent(event, tok, nonce, selfInvoke) {
    var eventCode = event.data;
    if ((!selfInvoke && eventCode == 1) || (selfInvoke && (eventCode == 1 || eventCode == 2 || eventCode == 0))) {
        $.ajax({
            url: `https://analogy.cs128.org/watch_record`,
            type: 'POST',
            dataType: 'json',
            data: {
            "event_code": eventCode,
            "playback_rate": event.target.playerInfo.playbackRate,
            "reported_location": event.target.playerInfo.currentTime,
            "total_duration": event.target.playerInfo.duration,
            "nonce": nonce,
            "token": tok
            },
            success: function (res) { },
            fail: function (res) { }
        });
    }
}